import React from 'react';
import styled from 'styled-components';
import { GlobalStyles } from '@/styles/globalStyles';
import { Header } from '@/features/new-landing-page/components/header';
import { ReviewsSection } from '@/features/new-landing-page/sections/reviews';
import { DisruptSection } from '@/features/new-landing-page/sections/disrupt';
import { Footer } from '@/features/new-landing-page/components/footer';
import { PageWrapper } from '@/features/new-landing-page/pages/homepage';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { CustomersContent } from '@/features/new-landing-page/pages/customers-content';
import { VSpace } from '@/components/spacing';
import { CustomersScroller } from '@/features/new-landing-page/components/customers-scroller';
import { CustomerCsCard } from '@/features/new-landing-page/components/customer-cs-card';
import { Section } from '@/features/new-landing-page/components/section';
import { breakpoints } from '@/components/responsive';

export function Customers() {
  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />

      <Section>
        <StrapiTextual {...CustomersContent.title} />
        <StrapiTextual {...CustomersContent.subtitle} />
        <VSpace value={2} />
        <CustomersScroller />
        <VSpace value={2} />
      </Section>

      <Section>
        <VSpace value={4} />
        <StrapiTextual {...CustomersContent.caseStudiesTitle} />
        <VSpace value={4} />
        <Grid>
          {CustomersContent.caseStudies.map((cs) => (
            <CustomerCsCard key={cs.link} {...cs} />
          ))}
        </Grid>
        <VSpace value={6} />
      </Section>

      <ReviewsSection />
      <DisruptSection ctaHref="https://apps.shopify.com/visually-io?utm_source=Website+&utm_medium=customers" />
      <Footer />
    </PageWrapper>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6rem;
  grid-row-gap: 8rem;

  padding: 0 10vw;

  ${breakpoints.down(`md`)} {
    grid-template-columns: 1fr;
    padding: 0 2rem;
  }
`;
