import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { VSpace } from '@/components/spacing';
import { breakpoints } from '@/components/responsive';

export interface CustomerCsCardProps {
  title: string;
  link: string;
  image: string;
}

export function CustomerCsCard({ image, link, title }: CustomerCsCardProps) {
  return (
    <Link style={{ textDecoration: `none` }} to={link}>
      <Wrapper>
        <img src={image} alt={title} />
        <VSpace value={3} className="only-desktop" />
        <VSpace value={2} className="only-mobile" />
        <span className="title">{title}</span>
        <VSpace value={1.5} className="only-desktop" />
        <VSpace value={1} className="only-mobile" />
        <span className="cta">
          Read Full Case Study <FaLongArrowAltRight />
        </span>
      </Wrapper>
    </Link>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Eesti, sans-serif;
  user-select: none;

  cursor: pointer;

  && > * {
    user-select: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
  }

  .title {
    font-size: 2.8rem;
    font-weight: 600;
    color: black;
    appearance: none;
    text-decoration: none;

    ${breakpoints.down(`md`)} {
      font-size: 2.4rem;
    }
  }

  .cta {
    font-size: 1.8rem;
    font-weight: 400;
    color: #ea1616;
    appearance: none;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    ${breakpoints.down(`md`)} {
      font-size: 1.6rem;
    }
  }
`;
